import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'


 

const MainMenu = (props) => {
  return (
    <Nav {...props} >
   
    <Nav.Link as={Link} to="http://www.altaynihatacar.com/" style={{color:"#ddac43", fontWeight:"700", fontSize:"21px", paddingLeft:"30px"}}>
    Altay Nihat ACAR
    </Nav.Link>
    <Nav.Link as={Link} to="http://www.ayfersenacar.com/" style={{color:"#ddac43", fontWeight:"700", fontSize:"21px", paddingLeft:"30px"}}>
    Ayfer ŞEN ACAR
    </Nav.Link>
   
  </Nav>
  )
}

export default MainMenu