import React from 'react';
import BackgroundLogo from '../components/common/background-logo';
import MyCard from '../components/common/my-card';
import Spacer from '../components/common/spacer';

const HomePage = () => {
  return (
    <>
    <BackgroundLogo/>
    <Spacer/>
    <MyCard/>
    <Spacer/>
    </>
  )
}

export default HomePage