export const config = {
    project: {
      name: "Dracar Grup Sağlık Turizm Limited Şti.",
      slogan:
        "Sağlığınıza Özel Cerrahi Çözümler",
      description:
        " Sağlığınızın her aşamasında yanınızda olan ekibimiz, kişiye özel cerrahi çözümlerle hizmet vermektedir.",
      version: "1.0.0",
    },
    contact: {
      phone1: "+90 (538) 418 27 47",
      phone2: "+90 (539) 602 93 37",
      email: "altaynihatacar@hotmail.com",
      address: "Seyrantepe mah. 65112 So. No:2 ŞEHİTKAMİL/GAZİANTEP",
      mapURL: "https://maps.app.goo.gl/csfFTGKEfMTbMgns5",
      mapEmbedURL:
        "https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d4502.130883877359!2d37.414606227539814!3d37.06969975951869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x1531e51939edbe89%3A0xe971287aebe89dd1!2sSeyrantepe%2C%20Abdulkadir%20Konuko%C4%9Flu%20Cd%20No%3A1%2C%2027080%20%C5%9Eehitkamil%2FGaziantep!3m2!1d37.070116299999995!2d37.4141741!5e0!3m2!1str!2str!4v1708858180296!5m2!1str!2str",
      socialMedia: {
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        instagram: "https://www.instagram.com/op.dr.ayfersenacar?igsh=MWZmNWE4dzZsZms3bA==",
        youtube: "https://youtube.com",
      },
    },
    api: {
      baseUrl: "https://dracar.com/api",
    },
   
  };
  
  
  