import React from "react";
import { FaWhatsapp } from "react-icons/fa6";
import "./iletisim.scss";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowAltCircleRight, FaInstagram } from "react-icons/fa";
import PageHeader from "../common/page-header";

const Iletisim = () => {
  return (
    <div>
      <h2 className="text-center">
        <PageHeader title=" 🟡  BİZE ULAŞIN  🟡" />
      </h2>
      <Container>
        <Row>
          <Col>
            <div className="text-center mt-3">
            <h3>Altay Nihat ACAR</h3>
            <p>Kalp ve Damar Cerrahi Uzmanı</p>
            </div>
            <div className="qr  mt-5">
              <a href="https://api.whatsapp.com/send?phone=905384182747">
                <FaWhatsapp
                  style={{
                    color: "green",
                    fontSize: "200px",
                  }}
                />
              </a>
            </div>
            <Col className="qr mt-5">
              <img
                src="/images/qr/qr.altayhoca.png"
                alt="whatsapp"
                width={180}
              />
            </Col>
            <Col className="qr">
              <p className="instagram">
                <a href="https://www.instagram.com/op.dr.altay.nihat.acar?igsh=MWlvYmJjeWl5bzAy">
                  {" "}
                  <FaInstagram />
                </a>
              </p>
            </Col>
            <Col>
              <div className=" text-center" style={{ fontSize: "30px" }}>
                <a
                  href="https://api.whatsapp.com/send?phone=905384182747"
                  style={{ textDecoration: "none" }}
                >
                  İletişim <FaArrowAltCircleRight /> 05384182747
                </a>
              </div>
            </Col>
          </Col>

          <Col>
          <div className="text-center mt-3">
            <h3>Ayfer ŞEN ACAR</h3>
            <p>Genel Cerrahi Uzmanı</p>
            </div>
            <div className="qr  mt-5">
              <a href="https://api.whatsapp.com/send?phone=905396029337">
                <FaWhatsapp
                  style={{
                    color: "green",
                    fontSize: "200px",
                  }}
                />
              </a>
            </div>
            <Col className="qr mt-5">
              <img
                src="/images/qr/qr.ayferhoca.png"
                alt="whatsapp"
                width={180}
              />
            </Col>
            <Col className="qr">
              <p className="instagram">
                <a href="https://www.instagram.com/op.dr.ayfersenacar/?igsh=MWZmNWE4dzZsZms3bA%3D%3D">
                  {" "}
                  <FaInstagram />
                </a>
              </p>
            </Col>
            <Col>
              <div className=" text-center" style={{ fontSize: "30px" }}>
                <a
                  href="https://api.whatsapp.com/send?phone=905396029337"
                  style={{ textDecoration: "none" }}
                >
                  İletişim <FaArrowAltCircleRight /> 05396029337
                </a>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Iletisim;
