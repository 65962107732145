import React from "react";
import AppRouter from "./router";

function App() {
  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;
