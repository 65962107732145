
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import UserLayout from "../layouts/user-layout";
import HomePage from "../pages/home-page";
import BizeUlasin from "../pages/bize-ulasin";




const router = createBrowserRouter([
    {
      path: "/",
      element: <UserLayout />,
      children: [
        {
          index: true,
          element: <HomePage />,
        },
        {
          path:"iletisim",
          element: <BizeUlasin/>
        }
    ]
    },
]);
const AppRouter = () => {
    return <RouterProvider router={router} />
      
      
    
  
  };
  
  export default AppRouter;