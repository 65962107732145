import React from 'react';
import { Card, Button } from 'react-bootstrap';
import "./my-card.scss"
import { FaArrowAltCircleRight } from 'react-icons/fa';

const MyCard = () => {
  return (
    <div className="container text-center">
      <div className="row  justify-content-center">
        <div className="col-lg-5 col-md-6 ">
          <Card className="border-0 mt-5">
            <a href='http://www.altaynihatacar.com'><Card.Img variant="top" src="/images/altaynihat.jpg" style={{ height: '500px', objectFit: 'cover', background:"black", border:"none" }}/></a>
            <Card.Body style={{  background:"black" }}>
              <Card.Title style={{fontWeight:"bold", color:"#C38B12", marginTop:"20px"}}>Altay Nihat ACAR</Card.Title>
              <Card.Text style={{color:"#C38B12"}}>Kalp ve Damar Cerrahi Uzmanı</Card.Text>
              <Button className='mt-3 w-100'style={{fontSize:"22px"}} variant="primary" href="http://www.altaynihatacar.com" target="_blank"> <FaArrowAltCircleRight className='icon-animation'/> Detaylar</Button>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-5 col-md-6">
          <Card className="border-0 mt-5">
            <a href="http://www.ayfersenacar.com"><Card.Img variant="top" src="/images/ayfersenhoca-1.png" style={{ height: '500px', objectFit: 'cover', background:"black", border:"none" }}/></a>
            <Card.Body style={{  background:"black" }}>

              <Card.Title style={{fontWeight:"bold", color:"#C38B12", marginTop:"20px"}}>Ayfer ŞEN ACAR</Card.Title>
              <Card.Text style={{color:"#C38B12"}}>Genel Cerrahi Uzmanı</Card.Text>
              
              <Button className='mt-3 w-100'style={{fontSize:"22px"}} variant="primary" href="http://www.ayfersenacar.com" target="_blank"> <FaArrowAltCircleRight className='icon-animation'/> Detaylar</Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default MyCard;
