import React from 'react'
import Menubar from '../components/common/menubar'
import { Outlet } from 'react-router-dom'
import Footer from '../components/common/footer'

const UserLayout = () => {
  return (
    <>
    <Menubar/>
    <Outlet/>
    <Footer/>
    </>
  )
}

export default UserLayout